import { useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types';
import {
  Paper,
  TextField,
  Button,
} from '@material-ui/core';

import PhoneIcon from 'Screens/Main/Sections/Contact/Blocks/ContactForm/Icons/PhoneIcon';
import MailIcon from 'Screens/Main/Sections/Contact/Blocks/ContactForm/Icons/MailIcon';
import useLocalized from 'CustomHooks/useLocalized';
import ValidationService from 'Services/ValidationService';
import Recaptcha from 'Components/Recaptcha/Recaptcha';
import {
  PHONE_NUMBER,
  EMAIL_ADDRESS,
  DIRECTOR_NAME,
  DIRECTOR_PHONE_NUMBER,
  OPERATIONS_MANAGER_NAME,
  OPERATIONS_MANAGER_PHONE_NUMBER,
  OPENING_HOURS,
} from 'Constants/Options';

import { NotificationContext } from 'App';
import WebService from 'Services/WebService';
import ErrorMessageService from 'Services/ErrorMessageService';

const initialFormState = {
  name: '',
  phoneNumber: '',
  email: '',
  notes: '',
};

const ContactForm = ({
  className,
}) => {
  const [contactData, setContactData] = useState(initialFormState);

  const handleChange = useCallback((e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  }, [contactData]);

  const [errors, setErrors] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    notes: false,
    captha: true,
  });

  const handleCapthaSuccess = useCallback(() => {
    setErrors({
      ...errors,
      captha: false,
    });
  }, [errors]);

  const validateForm = useCallback(() => {
    setErrors({
      ...errors,
      name: !contactData.name,
      phoneNumber: !contactData.phoneNumber,
      email: !contactData.email || !ValidationService.isValidEmail(contactData.email),
      notes: !contactData.notes,
    });
  }, [contactData.email, contactData.name, contactData.notes, contactData.phoneNumber, errors]);

  const isValidForm = useCallback(() => {
    validateForm();
    return !ValidationService.hasErrors(errors);
  }, [errors, validateForm]);


  const successMessage = useLocalized('contact_form_sent_success');
  const addNotification = useContext(NotificationContext);
  const [isSending, setIsSending] = useState(false);
  const handleFormSubmit = useCallback(() => {
    if (!isValidForm()) {
      return;
    }
    const sendRequestFormOptions = {
      method: 'POST',
      path: 'contact',
      data: contactData,
    };

    setIsSending(true);
    WebService.getSharedInstance().callWebservice(sendRequestFormOptions).then(() => {
      addNotification(successMessage, 'success');
      setIsSending(false);
      setContactData(initialFormState);
    }).catch((e) => {
      addNotification(ErrorMessageService.getErrorMessage(e), 'error');
      setIsSending(false);
    });
  }, [addNotification, contactData, isValidForm, successMessage]);

  return (
    <Paper className={`contact-form ${className}`}>
      <div className="contact-form__left">
        <div className="contact-form__title">
          {useLocalized('contact_details')}
        </div>
        <div className="contact-form__info">
          <div className="contact-form__info--icon">
            <PhoneIcon />
          </div>
          <a href={`tel: ${PHONE_NUMBER}`} className="contact-form__info--text">
            {PHONE_NUMBER}
          </a>
        </div>
        <div className="contact-form__info">
          <div className="contact-form__info--icon">
            <MailIcon />
          </div>
          <div className="contact-form__info--text">
            {EMAIL_ADDRESS}
          </div>
        </div>
        <div className="contact-form__info">
          <div className="contact-form__info--text">
            <b>
              {DIRECTOR_NAME}
            </b>
            {` - ${useLocalized('director')}`}
            <br />
            <a href={`tel: ${DIRECTOR_PHONE_NUMBER}`} className="contact-form__info--text">
              {DIRECTOR_PHONE_NUMBER}
            </a>
          </div>
        </div>
        <div className="contact-form__info">
          <div className="contact-form__info--text">
            <b>
              {OPERATIONS_MANAGER_NAME}
            </b>
            {` - ${useLocalized('operations_manager')}`}
            <br />
            <a href={`tel: ${OPERATIONS_MANAGER_PHONE_NUMBER}`} className="contact-form__info--text">
              {OPERATIONS_MANAGER_PHONE_NUMBER}
            </a>
          </div>
        </div>
        <div className="contact-form__line" />
        <div className="contact-form__title">
          {useLocalized('business_hours')}
        </div>
        <div className="contact-form__info-streched">
          <div className="contact-form__info--text">
            {useLocalized('weekdays')}
          </div>
          <div className="contact-form__info--text" dangerouslySetInnerHTML={{ __html: OPENING_HOURS.weekDays }} />
        </div>
        <div className="contact-form__info-streched">
          <div className="contact-form__info--text">
            {useLocalized('saturday')}
          </div>
          <div className="contact-form__info--text" dangerouslySetInnerHTML={{ __html: OPENING_HOURS.saturday }} />
        </div>
        <div className="contact-form__info-streched">
          <div className="contact-form__info--text">
            {useLocalized('sunday')}
          </div>
          <div className="contact-form__info--text" dangerouslySetInnerHTML={{ __html: OPENING_HOURS.sunday }} />
        </div>
      </div>
      <div className="contact-form__right">
        <div className="contact-form__title">
          {useLocalized('get_in_touch')}
        </div>
        <div className="contact-form__form-container">
          <TextField
            id="contact-form-name"
            label={useLocalized('form_name')}
            variant="filled"
            name="name"
            value={contactData.name}
            error={errors.name}
            onChange={handleChange}
            type="text"
            fullWidth
            required
          />
          <TextField
            id="contact-form-phone-number"
            label={useLocalized('form_phone_number')}
            variant="filled"
            name="phoneNumber"
            value={contactData.phoneNumber}
            error={errors.phoneNumber}
            onChange={handleChange}
            type="tel"
            fullWidth
            required
          />
          <TextField
            id="contact-form-email"
            label={useLocalized('form_email')}
            variant="filled"
            name="email"
            value={contactData.email}
            error={errors.email}
            onChange={handleChange}
            type="email"
            fullWidth
            required
          />
          <TextField
            id="contact-form-email"
            label={useLocalized('form_notes')}
            variant="filled"
            name="notes"
            value={contactData.notes}
            error={errors.notes}
            onChange={handleChange}
            type="text"
            multiline
            fullWidth
            required
          />
          <Recaptcha onSuccess={handleCapthaSuccess} />
          <Button
            onClick={handleFormSubmit}
            color="primary"
            variant="contained"
            fullWidth={false}
            size="large"
            disabled={isSending}
          >
            {useLocalized('send')}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

ContactForm.propTypes = {
  className: PropTypes.string,
};

export default ContactForm;
