import { useState, useCallback, useEffect, Fragment } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  Drawer,
  IconButton,
  Hidden,
  Slide,
  useScrollTrigger,
} from '@material-ui/core';
import {
  Menu,
  Close,
} from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';

import { NAVIGATION_ELEMENTS } from 'Constants/NavigationConstants';
import NavigationLink from 'Blocks/Navigation/Components/NavigationLink';
import MobileNavigationLink from 'Blocks/Navigation/Components/MobileNavigationLink';
import CompanyLogo from 'Blocks/Navigation/Components/CompanyLogo';

const Navigation = (props) => {
  const [open, setOpen] = useState(false);
  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const { pathname } = useLocation();
  const [inverted, setInverted] = useState(true);
  useEffect(() => {
    setInverted(pathname !== '/');
  }, [pathname]);

  const [invertedNavLinks, setInvertedNavlinks] = useState(false);

  const trigger = useScrollTrigger();

  const [appBarProps, setAppBarProps] = useState({
    position: "absolute",
    color: "transparent",
    elevation: 0,
  });

  const handleScroll = useCallback(() => {
    if (window.scrollY < window.innerHeight) {
      setAppBarProps({
        position: "absolute",
        color: "transparent",
        elevation: 0,
      });
      setInvertedNavlinks(false);
      if (pathname === '/') {
        setInverted(false);
      }
      return;
    }
    setAppBarProps({
      position: "fixed",
      color: "default",
      elevation: 1,
    });
    setInvertedNavlinks(true);
    setInverted(true);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <Slide direction="down" appear={false} in={!trigger}>
        <AppBar {...appBarProps}>
          <Toolbar disableGutters>
            <Container>
              <div className="navigation-container">
                <Link to="/" className={`navigation-container__title ${inverted ? 'inverted' : ''}`}>
                  <CompanyLogo inverted={inverted} />
                </Link>
                {pathname !== '/privacy-policy' &&
                  <>
                    <Hidden smDown>
                      <div className="navigation-container__elements">
                        {NAVIGATION_ELEMENTS.map(({
                          id,
                          label,
                          href,
                        }) => (
                          <NavigationLink
                            key={id}
                            id={id}
                            label={label}
                            href={href}
                            inverted={invertedNavLinks}
                          />
                        ))}
                      </div>
                    </Hidden>
                    <Hidden mdUp>
                      <div className="navigation-container__elements--mobile">
                        <IconButton onClick={toggleMenu}>
                          <Menu className={`hamburger-menu-icon ${inverted ? 'inverted' : ''}`} />
                        </IconButton>
                      </div>
                    </Hidden>
                  </>
                }
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </Slide>
      <Drawer anchor="right" open={open} onClose={toggleMenu}>
        <div className="mobile-menu">
          <div className="mobile-menu__top">
            <IconButton onClick={toggleMenu}>
              <Close />
            </IconButton>
          </div>
          <div className="mobile-menu__elements">
            {NAVIGATION_ELEMENTS.map(({
              id,
              label,
              href,
            }) => (
              <MobileNavigationLink
                key={id}
                id={id}
                label={label}
                href={href}
                onClick={toggleMenu}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Navigation;
