import { useCallback } from 'react';
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Container, Hidden, IconButton } from '@material-ui/core';

import useLocalized from 'CustomHooks/useLocalized';
import BackgroundImage from 'Screens/Main/Sections/Header/Assets/HeaderBackground.png'
import BookDriverForm from 'Screens/Main/Sections/Header/Blocks/BookDriverForm/BookDriverForm';
import ScrollDownIcon from 'Screens/Main/Sections/Header/Components/ScrollDownIcon/ScrollDownIcon';
import { NAVIGATION_SECTION_IDS } from 'Constants/NavigationConstants';

const Header = ({
  branchLocationOptions,
}) => {
  const history = useHistory();
  const handleScroll = useCallback(() => {
    history.push(`/#${NAVIGATION_SECTION_IDS.aboutUs}`)
  }, [history]);

  return (
    <>
      <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="header-section-top">
        <Container>
          <div className="header-section">
            <div className="header-section__left">
              <div className="title">
                {useLocalized('header_title')}
              </div>
              <div className="line" />
              <div className="description">
                {useLocalized('header_description')}
              </div>
            </div>
            <Hidden smDown>
              <div className="header-section__rigth">
                <BookDriverForm className="header-section__rigth--form" branchLocationOptions={branchLocationOptions} />
              </div>
            </Hidden>
            <IconButton onClick={handleScroll} className="header-section__button">
              <ScrollDownIcon />
            </IconButton>
          </div>
        </Container>
      </div>
      <Hidden mdUp>
        <Container>
          <BookDriverForm className="header-section__rigth--form" branchLocationOptions={branchLocationOptions} />
        </Container>
      </Hidden>
    </>
  )
}

Header.propTypes = {
  branchLocationOptions: PropTypes.object,
};

export default Header;
