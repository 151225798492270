import { NAVIGATION_SECTION_IDS } from 'Constants/NavigationConstants';
import useLocalized from 'CustomHooks/useLocalized';
import ContactForm from 'Screens/Main/Sections/Contact/Blocks/ContactForm/ContactForm';
import Map from 'Screens/Main/Sections/Contact/Blocks/Map/Map';
import BackgroundImage from 'Screens/Main/Sections/Contact/Assets/BackgroundImage.png';

const Contact = () => {
  return (
    <div className="contact-section" id={NAVIGATION_SECTION_IDS.contactUs}>
      <div className="contact-section__left" style={{ backgroundImage: `url(${BackgroundImage})` }}>
        <div className="contact-section__left--container">
          <div className="contact-section__left--title">
            {useLocalized('contact')}
          </div>
          <div className="contact-section__left--form-container">
            <ContactForm className="contact-section__left--form-container--form" />
          </div>
        </div>
      </div>
      <div className="contact-section__right">
        <div className="contact-section__right--map-container">
          <Map />
        </div>
      </div>
    </div>
  )
}

export default Contact;
