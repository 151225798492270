import format from 'date-fns/format';
import { DATE_FORMAT_BACKEND } from 'Constants/Options';

class DateService {
  static instance;
  static shared() {
    if (!this.instance) {
      return new DateService();
    }
    return this.instance;
  }
  
  getCurrentYear() {
    return new Date().getFullYear();
  }

  now() {
    return new Date();
  }

  tomorrow() { 
    return new Date().setDate(this.now().getDate() + 1);
  }

  isBefore(date1, date2) {
    return date1 < date2;
  }

  getDateStringForBackend(date) {
    if (!date) {
      return date;
    }
    return format(date, DATE_FORMAT_BACKEND)
  }
}

export default DateService;
