import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button } from '@material-ui/core';

import { NAVIGATION_SECTION_IDS } from 'Constants/NavigationConstants';
import VehicleHireIcon from 'Screens/Main/Sections/Services/Assets/VehicleHireIcon';
import DriverHireIcon from 'Screens/Main/Sections/Services/Assets/DriverHireIcon';
import useLocalized from 'CustomHooks/useLocalized';

const Services = () => {
  const history = useHistory();
  const handleNavigation = useCallback((to) => {
    history.push(to);
  }, [history]);

  return (
    <div className="services-section">
      <Container id={NAVIGATION_SECTION_IDS.services} className="services-section">
        <div className="services-section__title">
          {useLocalized('services_title')}
        </div>
        <div className="services-section__container">
          <div className="services-section__container--element">
            <VehicleHireIcon />
            <div className="service-element__title">
              {useLocalized('services_vehicle_hire')}
            </div>
            <div className="service-element__description">
              {useLocalized('services_vehicle_hire_description')}
            </div>
            <Button onClick={() => handleNavigation('/vehicle-hire')} color="primary" variant="contained">
              {useLocalized('services_vehicle_hire_button')}
            </Button>
          </div>
          <div className="services-section__container--element">
            <DriverHireIcon />
            <div className="service-element__title">
              {useLocalized('services_driver_hire')}
            </div>
            <div className="service-element__description">
              {useLocalized('services_driver_hire_description')}
            </div>
            <Button onClick={() => handleNavigation('/driver-hire')} color="secondary" variant="contained">
              {useLocalized('services_driver_hire_button')}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Services;
