import { createRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { RECAPTHA_KEY } from 'Constants/Options';

const Recaptcha = ({
  onSuccess,
}) => {
  const recaptchaRef = createRef();
  const handleChange = useCallback((value) => {
    if (value) {
      onSuccess();
    }
  }, [onSuccess]);

  const matches = useMediaQuery('(min-width:1400px)');

  const [captchaKey, setCaptchaKey] = useState(0);
  useEffect(() => {
    setCaptchaKey(captchaKey + 1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  return (
    <div className="recaptcha-container">
      <ReCAPTCHA
        key={captchaKey}
        ref={recaptchaRef}
        sitekey={RECAPTHA_KEY}
        onChange={handleChange}
        size={matches ? "normal" : "compact"}
      />
    </div>
  );
}

Recaptcha.proptTypes = {
  onSuccess: PropTypes.func,
};

export default Recaptcha;
