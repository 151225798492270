import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { NAVIGATION_ELEMENTS } from 'Constants/NavigationConstants';
import { COMPANY_NAME, COMPANY_CITY } from 'Constants/Options';
import NavigationLink from 'Blocks/Navigation/Components/NavigationLink';
import DateService from 'Services/DateService';

const Footer = () => {
  return (
    <>
      <div className="footer-top">
        <Container>
          <div className="navigation-container">
            <Link to="/" className="navigation-container__title">
              {COMPANY_NAME}
            </Link>
            <div className="navigation-container__elements">
              {NAVIGATION_ELEMENTS.map(({
                id,
                label,
                href,
              }) => (
                <NavigationLink
                  key={id}
                  id={id}
                  label={label}
                  href={href}
                />
              ))}
            </div>
          </div>
        </Container>
      </div>
      <div className="footer-bottom">
        {`© ${DateService.shared().getCurrentYear()} ${COMPANY_NAME} - ${COMPANY_CITY}`}
      </div>
    </>
  );
}

export default Footer;
