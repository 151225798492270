import useLocalized from 'CustomHooks/useLocalized';
import { Container } from '@material-ui/core';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-section">
      <Container>
        <div className="title">
          {useLocalized('privacy_policy_title')}
        </div>
        <div className="description">
          <p>{useLocalized('privacy_policy_description1')}</p>
          <p>{useLocalized('privacy_policy_description2')}</p>
          <p>{useLocalized('privacy_policy_description3')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description4')}</p>
          <p>{useLocalized('privacy_policy_description5')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description6')}</p>
          <p>{useLocalized('privacy_policy_description7')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description8')}</p>
          <p>{useLocalized('privacy_policy_description9')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description10')}</p>
          <p>{useLocalized('privacy_policy_description11')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description12')}</p>
          <p>{useLocalized('privacy_policy_description13')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description14')}</p>
          <p>{useLocalized('privacy_policy_description15')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description16')}</p>
          <p>{useLocalized('privacy_policy_description17')}</p>
          <p className="subtitle">{useLocalized('privacy_policy_description18')}</p>
          <p>{useLocalized('privacy_policy_description19')} <a href={`mailto:${useLocalized('privacy_policy_description20')}`} className="link-style">{useLocalized('privacy_policy_description20')}</a></p>
        </div>
      </Container>
    </div>
  )
};

export default PrivacyPolicy;
