import { useState, useCallback, useContext } from 'react'
import useLocalized from 'CustomHooks/useLocalized';
import {TextField, Button } from '@material-ui/core';
import ValidationService from 'Services/ValidationService';
import PhoneIcon from 'Screens/Main/Sections/Contact/Blocks/ContactForm/Icons/PhoneIcon';
import MailIcon from 'Screens/Main/Sections/Contact/Blocks/ContactForm/Icons/MailIcon';
import { NotificationContext } from 'App';
import WebService from 'Services/WebService';
import ErrorMessageService from 'Services/ErrorMessageService';

const initialFormState = {
  name: '',
  phoneNumber: '',
  email: '',
  notes: '',
};

const DeleteAccount = () => {
  const [contactData, setContactData] = useState(initialFormState);

  const handleChange = useCallback((e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  }, [contactData]);

  const [errors, setErrors] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    notes: false,
  });

  const validateForm = useCallback(() => {
    setErrors({
      ...errors,
      name: !contactData.name,
      phoneNumber: !contactData.phoneNumber,
      email: !contactData.email || !ValidationService.isValidEmail(contactData.email),
      notes: !contactData.notes,
    });
  }, [contactData.email, contactData.name, contactData.notes, contactData.phoneNumber, errors]);

  const isValidForm = useCallback(() => {
    validateForm();
    return !ValidationService.hasErrors(errors);
  }, [errors, validateForm]);

  const successMessage = useLocalized('contact_form_sent_success');
  const addNotification = useContext(NotificationContext);
  const [isSending, setIsSending] = useState(false);
  const handleFormSubmit = useCallback(() => {
    if (!isValidForm()) {
      return;
    }
    const sendRequestFormOptions = {
      method: 'POST',
      path: 'contact',
      data: contactData,
    };

    setIsSending(true);
    WebService.getSharedInstance().callWebservice(sendRequestFormOptions).then(() => {
      addNotification(successMessage, 'success');
      setIsSending(false);
      setContactData(initialFormState);
    }).catch((e) => {
      addNotification(ErrorMessageService.getErrorMessage(e), 'error');
      setIsSending(false);
    });
  }, [addNotification, contactData, isValidForm, successMessage]);

  return (
    <div class='delete-account-container'>
      <div class='title-container'>
        <span>{useLocalized('delete_account_title')}</span>
      </div>
      <div class='form-container'>
        <div class='form-container-left'>
          <span class='sub-title'>{useLocalized('account_deletion_request_title')}</span>
          <span class='description'>{useLocalized('account_deletion_request_description')}</span>
          <div class='image-text-container'>
            <div class='image-text'>
              <PhoneIcon />
              <a href={`tel:${useLocalized('account_deletion_request_phone')}`}>{useLocalized('account_deletion_request_phone')}</a>
            </div>
            <div class='image-text'>
              <MailIcon />
              <a href={`mailto:${useLocalized('account_deletion_request_mail')}`}>{useLocalized('account_deletion_request_mail')}</a>
            </div>
          </div>
        </div>
        <div class='divider' />
        <div class='form-container-right'>
          <div class='sub-title-container'>
            <span class='sub-title'>{useLocalized('account_deletion_request_form_title')}</span>
            <span class='description'>{useLocalized('account_deletion_request_form_description')}</span>
          </div>
          <div class='form-filed-container'>
            <TextField
              id='contact-form-name'
              label={useLocalized('form_name')}
              variant='filled'
              name='name'
              value={contactData.name}
              error={errors.name}
              onChange={handleChange}
              type='text'
              fullWidth
              required
            />
            <TextField
              id='contact-form-phone-number'
              label={useLocalized('form_phone_number')}
              variant='filled'
              name='phoneNumber'
              value={contactData.phoneNumber}
              error={errors.phoneNumber}
              onChange={handleChange}
              type='tel'
              fullWidth
              required
            />
            <TextField
              id='contact-form-email'
              label={useLocalized('form_email')}
              variant='filled'
              name='email'
              value={contactData.email}
              error={errors.email}
              onChange={handleChange}
              type='email'
              fullWidth
              required
            />
            <TextField
              id='contact-form-email'
              label={useLocalized('form_notes')}
              variant='filled'
              name='notes'
              value={contactData.notes}
              error={errors.notes}
              onChange={handleChange}
              type='text'
              multiline
              rowsMax={4}
              fullWidth
              required
            />
          </div>
          <Button
            onClick={handleFormSubmit}
            color='primary'
            variant='contained'
            fullWidth={true}
            size='large'
            disabled={isSending}
          >
            {useLocalized('send')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
