import { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ScrollHandler = () => {
  const history = useHistory();
  const { hash } = useLocation();

  const handleScroll = useCallback((hash) => {
    const hashWithoutParams = hash.split('?')[0];
    const element = document.getElementById(hashWithoutParams.replace('#', ''));
    if (element) {
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop : 0
        });
      }, 100);
      return;
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleScroll(hash);
  }, [handleScroll, hash]);

  history.listen(({ hash }) => {
    handleScroll(hash);
  });
  
  return null;
};

export default ScrollHandler;
