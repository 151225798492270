import { SUPPORTED_LOCALES } from 'Constants/Options';
import English from 'Constants/English.json';

class LocalizationService {
  static instance;
  static shared() {
    if (!this.instance) {
      return new LocalizationService();
    }
    return this.instance;
  }

  getLocalizedText(locale, key) {
    switch (locale) {
      case SUPPORTED_LOCALES.en_GB:
        return English[key];
      default:
        return English[key];
    }
  }
}

export default LocalizationService;
