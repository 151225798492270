import { Container, Grid } from '@material-ui/core';

import { NAVIGATION_SECTION_IDS } from 'Constants/NavigationConstants';
import useLocalized from 'CustomHooks/useLocalized';
import ImagePresenter from 'Components/ImagePresenter/ImagePresenter';
import {
  selcoBuilders,
  lords,
  raven,
  surreySteel,
  pbmBuilders,
  allenConcrete,
  maTrans,
} from 'Screens/Main/Sections/Partners/Assets';

const PARTNER_LIST = [
  {
    id: 'selcoBuilders',
    logo: selcoBuilders,
  },
  {
    id: 'lords',
    logo: lords,
  },
  {
    id: 'raven',
    logo: raven,
  },
  {
    id: 'surreySteel',
    logo: surreySteel,
  },
  {
    id: 'pbmBuilders',
    logo: pbmBuilders,
  },
  {
    id: 'allenConcrete',
    logo: allenConcrete,
  },
  {
    id: 'maTrans',
    logo: maTrans,
  },
];

const Partners = () => {
  return (
    <div id={NAVIGATION_SECTION_IDS.partners} className="partners-section">
      <div className="partners-section__title">
        {useLocalized('partners_title')}
      </div>
      <Container className="partners-section__list">

        {/* Inline style is needed to correct a known MUI Grid bug causing vertical scroll */}
        <Grid container spacing={8} style={{ margin: 0, width: '100%' }}>
          {PARTNER_LIST.map(({
            id,
            logo,
          }) => (
            <Grid
              item
              key={id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="partners-section__list--element"
            >
              <ImagePresenter
                containerClass="partners-section__list--element"
                imageClass="partners-section__list--element--image"
                src={logo}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default Partners;
