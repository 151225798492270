import { useState, createContext, useCallback } from 'react';
import {
  CssBaseline
} from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter, Route } from 'react-router-dom'

import Notifications from 'Components/Notifications/Notifications';
import Navigation from 'Blocks/Navigation/Navigation';
import Footer from 'Blocks/Footer/Footer';
import MainScreen from 'Screens/Main/MainScreen';
import DriverHireScreen from 'Screens/Services/DriverHireScreen';
import VehicleHireScreen from 'Screens/Services/VehicleHireScreen';
import ScrollHandler from 'Components/ScrollHandler/ScrollHandler';
import PrivacyPolicy from 'Screens/PrivacyPolicy/PrivacyPolicy';
import DeleteAccount from 'Screens/DeleteAccount/DeleteAccount';
import theme from 'Styles/theme.json';
import 'main.scss';

const muiTheme = createMuiTheme(theme);

export const NotificationContext = createContext({});

const App = () => {
  const [notifications, setNotifications] = useState([]);
  const addNotification = useCallback((text, severity) => {
    setNotifications([
      ...notifications,
      {
        text,
        severity,
      }
    ]);
    setTimeout(() => {
      const newNotifications = [...notifications];
      newNotifications.shift();
      setNotifications(newNotifications);
    }, 5000);
  }, [notifications]);

  return (
    <ThemeProvider theme={muiTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <NotificationContext.Provider value={addNotification}>
          <BrowserRouter>
            <Navigation />

            <Route exact path='/' render={() => <MainScreen />} />
            <Route path='/vehicle-hire' component={() => <VehicleHireScreen />} />
            <Route path='/driver-hire' component={() => <DriverHireScreen />} />
            <Route path='/privacy-policy' component={() => <PrivacyPolicy />} />
            <Route path='/delete-account' component={() => <DeleteAccount />} />

            <Footer />
            <ScrollHandler />
          </BrowserRouter>
          {notifications && <Notifications notifications={notifications} />}
        </NotificationContext.Provider>
      </MuiPickersUtilsProvider>
      
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
