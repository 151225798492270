import { useState, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import useLocalized from 'CustomHooks/useLocalized';
import ValidationService from 'Services/ValidationService';
import DateService from 'Services/DateService';
import Recaptcha from 'Components/Recaptcha/Recaptcha';
import { PHONE_NUMBER, DATE_FORMAT } from 'Constants/Options';
import { NotificationContext } from 'App';
import WebService from 'Services/WebService';
import ErrorMessageService from 'Services/ErrorMessageService';

const SERVICE_OPTIONS = [
  {
    id: "26 T lorry with driver",
    name: "26 T lorry with driver",
  },
  {
    id: "18 T lorry with driver",
    name: "18 T lorry with driver",
  },
  {
    id: "3.5 T van with driver ",
    name: "3.5 T van with driver ",
  },
  {
    id: "Class 1 lorry drivers with Crane Licence",
    name: "Class 1 lorry drivers with Crane Licence",
  },
  {
    id: "Class 2 lorry drivers with Crane Licence",
    name: "Class 2 lorry drivers with Crane Licence",
  },
  {
    id: "Van drivers",
    name: "Van drivers",
  },
  {
    id: "Forklift drivers",
    name: "Forklift drivers",
  },
  {
    id: "Pickers",
    name: "Pickers",
  },
];

const initialFormState = {
  name: '',
  phoneNumber: '',
  email: '',
  notes: '',
  service: '',
  company: '',
  branchLocation: '',
  startDate: null,
  endDate: null,
};

const BookDriverForm = ({
  className,
}) => {
  const [bookDriverData, setBookDriverData] = useState(initialFormState);

  const handleChange = useCallback((e) => {
    setBookDriverData({
      ...bookDriverData,
      [e.target.name]: e.target.value,
    });
  }, [bookDriverData]);

  const handleDateChange = useCallback((key, value) => {
    setBookDriverData({
      ...bookDriverData,
      [key]: value,
    });
  }, [bookDriverData]);

  const [minEndDate, setMinEndDate] = useState(bookDriverData.startDate);
  useEffect(() => {
    if (DateService.shared().isBefore(minEndDate, bookDriverData.startDate)) {
      handleDateChange('endDate', bookDriverData.startDate);
      setMinEndDate(bookDriverData.startDate);
    } else {
      setMinEndDate(bookDriverData.startDate);
    }
  }, [bookDriverData.startDate, handleDateChange, minEndDate]);

  const [errors, setErrors] = useState({
    name: false,
    phoneNumber: false,
    email: false,
    service: false,
    company: false,
    branchLocation: false,
    startDate: false,
    endDate: false,
    captha: true,
  });

  const handleCapthaSuccess = useCallback(() => {
    setErrors({
      ...errors,
      captha: false,
    });
  }, [errors]);

  const validateForm = useCallback(() => {
    setErrors({
      ...errors,
      name: !bookDriverData.name,
      phoneNumber: !bookDriverData.phoneNumber,
      email: !bookDriverData.email || !ValidationService.isValidEmail(bookDriverData.email),
      service: !bookDriverData.service,
      company: !bookDriverData.company,
      branchLocation: !bookDriverData.branchLocation,
      startDate: !bookDriverData.startDate,
      endDate: !bookDriverData.endDate,
    });
  }, [bookDriverData.branchLocation, bookDriverData.company, bookDriverData.email, bookDriverData.endDate, bookDriverData.name, bookDriverData.phoneNumber, bookDriverData.service, bookDriverData.startDate, errors]);

  const isValidForm = useCallback(() => {
    validateForm();
    return !ValidationService.hasErrors(errors);
  }, [errors, validateForm]);

  const successMessage = useLocalized('request_sent_success');
  const addNotification = useContext(NotificationContext);
  const [isSending, setIsSending] = useState(false);
  const handleFormSubmit = useCallback(() => {
    if (!isValidForm()) {
      return;
    }

    const sendRequestFormOptions = {
      method: 'POST',
      path: 'book-driver',
      data: { 
        ...bookDriverData,
        startDate: DateService.shared().getDateStringForBackend(bookDriverData.startDate),
        endDate: DateService.shared().getDateStringForBackend(bookDriverData.endDate),
      },
    };

    setIsSending(true);
    WebService.getSharedInstance().callWebservice(sendRequestFormOptions).then(() => {
      addNotification(successMessage, 'success');
      setIsSending(false);
      setBookDriverData(initialFormState);
    }).catch((e) => {
      addNotification(ErrorMessageService.getErrorMessage(e), 'error');
      setIsSending(false);
    });
  }, [addNotification, bookDriverData, isValidForm, successMessage]);

  return (
    <Paper className={`book-driver-form ${className}`}>
      <div className="book-driver-form__title">
        {useLocalized('book_driver_form_title')}
      </div>
      <div className="book-driver-form__form-container">
        <TextField
          id="book-driver-form-name"
          label={useLocalized('form_name')}
          variant="filled"
          name="name"
          value={bookDriverData.name}
          error={errors.name}
          onChange={handleChange}
          type="text"
          fullWidth
          required
        />
        <FormControl variant="filled" error={errors.service}>
          <InputLabel id="book-driver-form-service-label" required>{useLocalized('form_service')}</InputLabel>
          <Select
            labelId="book-driver-form-service-select-label"
            id="book-driver-form-service"
            value={bookDriverData.service}
            name="service"
            onChange={handleChange}
            error={errors.service}
            inputProps={{
              MenuProps: {
                disableScrollLock: true,
              }
            }}
          >
            {SERVICE_OPTIONS.map(({
              id,
              name,
            }) => <MenuItem key={id} value={name}>{name}</MenuItem>)}
          </Select>
        </FormControl>
        <TextField
          id="book-driver-form-company"
          label={useLocalized('form_company')}
          variant="filled"
          name="company"
          value={bookDriverData.company}
          error={errors.company}
          onChange={handleChange}
          type="text"
          fullWidth
          required
        />
        <TextField
          id="book-driver-form-branch_location"
          label={useLocalized('form_branch_location')}
          variant="filled"
          name="branchLocation"
          value={bookDriverData.branchLocation}
          error={errors.branchLocation}
          onChange={handleChange}
          type="text"
          fullWidth
          required
        />
        <TextField
          id="book-driver-form-phone-number"
          label={useLocalized('form_phone_number')}
          variant="filled"
          name="phoneNumber"
          value={bookDriverData.phoneNumber}
          error={errors.phoneNumber}
          onChange={handleChange}
          type="tel"
          fullWidth
          required
        />
        <TextField
          id="book-driver-form-email"
          label={useLocalized('form_email')}
          variant="filled"
          name="email"
          value={bookDriverData.email}
          error={errors.email}
          onChange={handleChange}
          type="email"
          fullWidth
          required
        />
        <div className="book-driver-form__form-container--two-elements">
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={DATE_FORMAT}
            margin="normal"
            id="book-driver-form-start-date"
            label={useLocalized('form_start_date')}
            value={bookDriverData.startDate}
            error={errors.startDate}
            onChange={date => handleDateChange('startDate', date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minDate={DateService.shared().tomorrow()}
            autoOk
            fullWidth
            PopoverProps={{
              disableScrollLock: true,
            }}
            className="book-driver-form__form-container--two-elements--element"
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format={DATE_FORMAT}
            margin="normal"
            id="book-driver-form-end-date"
            label={useLocalized('form_end_date')}
            value={bookDriverData.endDate}
            error={errors.endDate}
            onChange={date => handleDateChange('endDate', date)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disablePast
            autoOk
            minDate={minEndDate}
            fullWidth
            PopoverProps={{
              disableScrollLock: true,
            }}
            className="book-driver-form__form-container--two-elements--element"
          />
        </div>
        <TextField
          id="book-driver-form-notes"
          label={useLocalized('form_notes')}
          variant="filled"
          name="notes"
          value={bookDriverData.notes}
          onChange={handleChange}
          type="text"
          multiline
          fullWidth
        />
        <Recaptcha onSuccess={handleCapthaSuccess} />
        <Button
          onClick={handleFormSubmit}
          color="primary"
          variant="contained"
          fullWidth={false}
          size="large"
          disabled={isSending}
        >
          {useLocalized('send')}
        </Button>
        <a href={`tel: ${PHONE_NUMBER}`} className="book-driver-form__form-container--bottom-description">
          {`${useLocalized('or_call_us')} - ${PHONE_NUMBER}`}
        </a>
      </div>
    </Paper>
  );
};

BookDriverForm.propTypes = {
  className: PropTypes.string,
  branchLocationOptions: PropTypes.object,
};

export default BookDriverForm;
