import LocalizationService from 'Services/LocalizationService';
import { SUPPORTED_LOCALES } from 'Constants/Options';

const useLocalized = (key) => {

  // If needed locale can be received from a context right now we are supporting only one language so it is hardcoded
  const locale = SUPPORTED_LOCALES.en_GB;
  const translatedText = LocalizationService.shared().getLocalizedText(locale, key);
  if (!translatedText) {
    return key;
  }
  return translatedText;
};

export default useLocalized;
