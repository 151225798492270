import PropTypes from 'prop-types';

import Header from 'Screens/Main/Sections/Header/Header';
import AboutUs from 'Screens/Main/Sections/AboutUs/AboutUs';
import Services from 'Screens/Main/Sections/Services/Services';
import Partners from 'Screens/Main/Sections/Partners/Partners';
import Contact from 'Screens/Main/Sections/Contact/Contact';

const MainScreen = ({
  branchLocationOptions,
}) => {
  return (
    <>
      <Header branchLocationOptions={branchLocationOptions} />
      <AboutUs />
      <Services />
      <Partners />
      <Contact />
    </>
  );
}

MainScreen.propTypes = {
  branchLocationOptions: PropTypes.object,
};

export default MainScreen;
