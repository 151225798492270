export const SUPPORTED_LOCALES = {
  en_GB: 'en_GB',
};

export const COMPANY_NAME = 'Lena Transport';
export const COMPANY_CITY = 'london'
export const COMPANY_LOCATION = [51.24, -0.54];
export const COMPANY_ADDRESS = `
  98 Bushy Hill Drive
  <br>
  Merrow
  <br>
  Guildford
  <br>
  GU1 2UH
`;
export const PHONE_NUMBER = '0148.385.4480';
export const EMAIL_ADDRESS = 'lenatransportltd@yahoo.co.uk';
export const DIRECTOR_NAME = 'Lena Meszaros';
export const DIRECTOR_PHONE_NUMBER = '07739 490237';
export const OPERATIONS_MANAGER_NAME = 'Attila Magyari';
export const OPERATIONS_MANAGER_PHONE_NUMBER = '07809 226636';
export const OPENING_HOURS = {
  weekDays: '9:00<sup>AM</sup> - 8:00<sup>PM</sup>',
  saturday: '9:00<sup>AM</sup> - 5:00<sup>PM</sup>',
  sunday: 'Closed',
}

export const CURRENCY = '£';
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_FORMAT_BACKEND = 'dd-MM-yyyy';

export const RECAPTHA_KEY = '6Le7SIAaAAAAAD5TBH7IIDNXUMf3mjdx6jzEWHPV';

export const BASE_URL = 'https://backend.lenatransport.co.uk';
