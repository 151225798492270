const ComanyLogo = ({ inverted, ...props }) => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100px" viewBox="0 0 694.13 300" style={{enableBackground: 'new 0 0 694.13 300'}} {...props}>
  <g>
    <g>
      <polygon fill={ inverted ? "#000000" : "#FFFFFF" } points="58.14,0 0.01,0 0.01,164.66 144.1,164.66 144.1,122 58.14,122"/>
      <polygon fill={ inverted ? "#000000" : "#FFFFFF" } points="224.79,102.54 294.49,102.54 294.49,63.12 224.79,63.12 224.79,42.66 309.71,42.66 309.71,0 166.66,0 166.66,164.66 312.7,164.66 312.7,122 224.79,122" />
      <polygon fill={ inverted ? "#000000" : "#FFFFFF" } points="466.33,61.12 467.33,95.05 466.83,95.05 404.23,0 341.36,0 341.36,164.66 391.76,164.66 391.76,103.54 390.76,69.61 391.26,69.61 453.85,164.66 516.73,164.66 516.73,0 466.33,0"/>
      <path fill={ inverted ? "#000000" : "#FFFFFF" } d="M548.09,0v164.66h50.81v-42.91h44.41v42.91h50.81V0H548.09z M643.31,82.33h-44.41V42.66h44.41V82.33z" />
    </g>
    <g>
      <g>
        <polygon fill="#16BECF" points="166.8,237.04 183.6,237.04 183.6,279.24 191.55,279.24 191.55,237.04 208.34,237.04 208.34,230.2 166.8,230.2" />
        <path fill="#16BECF" d="M263.13,253.72c1.81-2.35,2.71-5.29,2.71-8.8c0-4.61-1.54-8.21-4.61-10.81c-3.07-2.6-7.26-3.9-12.56-3.9
          H228.7v49.04h7.88v-19.62h10.55l12.48,19.62h9.14l-13.3-20.51C258.76,257.74,261.32,256.07,263.13,253.72z M248.01,253.01h-11.44v-16.05h11.44c3.22,0,5.62,0.66,7.21,1.97c1.59,1.31,2.38,3.33,2.38,6.06c0,2.72-0.79,4.74-2.38,6.06C253.63,252.35,251.23,253.01,248.01,253.01z" />
        <path fill="#16BECF" d="M302.18,230.2l-18.5,49.04h8.17l4.68-12.85h20.65l4.61,12.85h8.4l-18.5-49.04H302.18z M298.83,259.92l5.8-15.9l2.08-6.91h0.22l2.23,7.06l5.65,15.75H298.83z" />
        <polygon fill="#16BECF" points="382.57,261.85 382.87,270.17 382.79,270.17 377.96,261.03 359.09,230.2 349.73,230.2 349.73,279.24 357.01,279.24 357.01,247.59 356.72,239.26 356.79,239.26 361.54,248.33 380.49,279.24 389.93,279.24 389.93,230.2 382.57,230.2" />
        <path fill="#16BECF" d="M444.35,254.38c-2.6-1.21-6.1-2.22-10.51-3.01c-4.71-0.84-7.98-1.84-9.81-3.01c-1.83-1.16-2.75-2.74-2.75-4.72c0-2.13,0.94-3.87,2.82-5.24c1.88-1.36,4.53-2.04,7.95-2.04c3.47,0,6.24,0.67,8.32,2.01c2.08,1.34,3.81,3.49,5.2,6.46l6.02-5.42c-2.18-3.37-4.87-5.87-8.06-7.5c-3.2-1.63-7.05-2.45-11.55-2.45c-3.86,0-7.24,0.67-10.14,2.01c-2.9,1.34-5.11,3.15-6.65,5.42c-1.54,2.28-2.3,4.81-2.3,7.58c0,2.63,0.61,4.86,1.82,6.69c1.21,1.83,3.16,3.37,5.83,4.61c2.67,1.24,6.27,2.28,10.77,3.12c4.51,0.84,7.66,1.81,9.47,2.9c1.81,1.09,2.71,2.53,2.71,4.31c0,2.23-1,3.95-3.01,5.16c-2.01,1.21-4.77,1.82-8.28,1.82c-3.72,0-6.82-0.69-9.32-2.08c-2.5-1.39-4.55-3.57-6.13-6.54l-5.57,5.57c4.11,6.64,11.15,9.96,21.1,9.96c3.86,0,7.27-0.58,10.22-1.75c2.95-1.16,5.24-2.84,6.87-5.02c1.63-2.18,2.45-4.7,2.45-7.58c0-2.67-0.59-4.92-1.78-6.72S446.95,255.6,444.35,254.38z" />
        <path fill="#16BECF" d="M505.28,234.29c-3.1-2.72-7.29-4.09-12.59-4.09h-18.87v49.04h7.95v-18.5h10.92c5.3,0,9.5-1.35,12.59-4.05c3.1-2.7,4.64-6.43,4.64-11.18C509.92,240.75,508.37,237.01,505.28,234.29z M499.15,251.86c-1.69,1.41-4.26,2.12-7.73,2.12h-9.66v-17.01h9.66c6.84,0,10.25,2.85,10.25,8.54C501.67,248.33,500.83,250.45,499.15,251.86z" />
        <path fill="#16BECF" d="M563.9,232.5c-3.49-2.03-7.59-3.05-12.3-3.05s-8.79,1.02-12.26,3.05c-3.47,2.03-6.14,4.95-8.02,8.77c-1.88,3.82-2.82,8.3-2.82,13.45c0,5.15,0.94,9.63,2.82,13.45s4.56,6.74,8.02,8.77c3.47,2.03,7.55,3.05,12.26,3.05s8.81-1.01,12.3-3.05c3.49-2.03,6.18-4.95,8.06-8.77c1.88-3.81,2.82-8.3,2.82-13.45c0-5.15-0.94-9.63-2.82-13.45C570.08,237.46,567.39,234.54,563.9,232.5z M562.52,268.24c-2.63,3.22-6.27,4.83-10.92,4.83c-4.61,0-8.22-1.61-10.85-4.83c-2.63-3.22-3.94-7.73-3.94-13.52c0-5.8,1.31-10.3,3.94-13.52c2.63-3.22,6.24-4.83,10.85-4.83c4.66,0,8.3,1.61,10.92,4.83c2.63,3.22,3.94,7.73,3.94,13.52C566.46,260.51,565.15,265.02,562.52,268.24z"/>
        <path fill="#16BECF" d="M631.88,253.72c1.81-2.35,2.71-5.29,2.71-8.8c0-4.61-1.54-8.21-4.61-10.81c-3.07-2.6-7.26-3.9-12.56-3.9h-19.99v49.04h7.88v-19.62h10.55l12.48,19.62h9.14l-13.3-20.51C627.51,257.74,630.07,256.07,631.88,253.72z M616.76,253.01h-11.44v-16.05h11.44c3.22,0,5.62,0.66,7.21,1.97c1.59,1.31,2.38,3.33,2.38,6.06c0,2.72-0.79,4.74-2.38,6.06C622.38,252.35,619.98,253.01,616.76,253.01z" />
        <polygon fill="#16BECF" points="651.98,230.2 651.98,237.04 668.77,237.04 668.77,279.24 676.72,279.24 676.72,237.04 693.51,237.04 693.51,230.2" />
        <polygon fill="#16BECF" points="44.65,209.79 0,209.79 30.94,254.89 0,300 44.65,300 75.59,254.89" />
        <polygon fill="#16BECF" points="68.51,209.79 99.45,254.89 68.51,300 113.16,300 144.1,254.89 113.16,209.79" />
      </g>
    </g>
  </g>
  </svg>
);

export default ComanyLogo;
