const ScrollDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25.473" height="41.908" viewBox="0 0 25.473 41.908">
    <g id="Scroll_down" data-name="Scroll down" transform="translate(-947.172 -869)" opacity="0.75">
      <g id="Ellipse_22" data-name="Ellipse 22" transform="translate(950 869)" fill="none" stroke="#fff" strokeWidth="4">
        <circle cx="10" cy="10" r="10" stroke="none" />
        <circle cx="10" cy="10" r="8" fill="none" />
      </g>
      <path id="Path_17" data-name="Path 17" d="M-1019,2352.972l9.908,9.908,9.908-9.908" transform="translate(1969 -1453.972)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
    </g>
  </svg>
);

export default ScrollDownIcon;
