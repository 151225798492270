const MailIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="email-24px" transform="translate(0 3)">
      <path id="Path_109" data-name="Path 109" d="M0,0H24V24H0Z" transform="translate(0 -3)" fill="none"/>
      <path id="Path_110" data-name="Path 110" d="M20,4H4A2,2,0,0,0,2.01,6L2,18a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,4-8,5L4,8V6l8,5,8-5Z" transform="translate(0 -3)" fill="#029eff"/>
    </g>
  </svg>
);

export default MailIcon;
