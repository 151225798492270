export const NAVIGATION_SECTION_IDS = {
  aboutUs: 'about_us_section',
  contactUs: 'contact_us_section',
  services: 'services_section',
  partners: 'partners_section',
};

export const NAVIGATION_ELEMENTS = [{
  id: 'nav_about_us',
  label: 'about_us',
  href: NAVIGATION_SECTION_IDS.aboutUs,
}, {
  id: 'nav_services',
  label: 'services',
  href: NAVIGATION_SECTION_IDS.services,
}, {
  id: 'nav_partners',
  label: 'partners',
  href: NAVIGATION_SECTION_IDS.partners,
}, {
  id: 'nav_contact_us',
  label: 'contact_us',
  href: NAVIGATION_SECTION_IDS.contactUs,
}];
