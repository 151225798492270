import PropTypes from 'prop-types';

const ImagePresenter = ({
  src,
  alt,
  containerClass,
  imageClass,
}) => {
  return (
    <div className={`image-presenter ${containerClass}`}>
      <img src={src} alt={alt} className={`image-presenter__image ${imageClass}`} />
    </div>
  );
}

ImagePresenter.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  containerClass: PropTypes.string,
  imageClass: PropTypes.string,
};

export default ImagePresenter;
