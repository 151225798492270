const DriverHireIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="114.67" height="89.607" viewBox="0 0 114.67 89.607" {...props}>
    <g id="Driver_icon" data-name="Driver icon" transform="translate(-1088.429 -483.784)">
      <path id="Path_102" data-name="Path 102" d="M1188.282,547.711h-3.223l.019-2.493a15.782,15.782,0,0,0,11.066-12.959l.142-.016a5.269,5.269,0,0,0,0-10.538v-2.287a12.911,12.911,0,0,0-12.913-12.91h-5.7a12.876,12.876,0,0,0-8.682,3.369s-.546.564-.945,1.055l.009-.973v-2.952a15.88,15.88,0,0,0-15.88-15.879h-6.521a15.879,15.879,0,0,0-15.879,15.879v2.952l.008.973c-.4-.491-.945-1.055-.945-1.055a12.872,12.872,0,0,0-8.682-3.369h-5.7a12.91,12.91,0,0,0-12.912,12.91v2.287a5.269,5.269,0,0,0,0,10.538l.143.016a15.78,15.78,0,0,0,11.066,12.959l.018,2.493h-3.223A13.348,13.348,0,0,0,1096.2,561.06h0v7.6a4.726,4.726,0,0,0,4.726,4.727h95.98a4.726,4.726,0,0,0,4.726-4.727v-7.6h0A13.349,13.349,0,0,0,1188.282,547.711Zm-45.358-5.906h-3.3a16.381,16.381,0,0,0-12.72,6.043,13.524,13.524,0,0,0-1.842-.139h-3.214v-2.491a15.764,15.764,0,0,0,11.185-14.063,19.424,19.424,0,0,0,9.892,7.59Zm33.056,5.9h-3.213a13.512,13.512,0,0,0-1.842.139A16.382,16.382,0,0,0,1158.2,541.8h-3.3v-3.06a19.424,19.424,0,0,0,9.892-7.59,15.763,15.763,0,0,0,11.184,14.063Z" fill="rgba(20,189,212,0.5)"/>
      <g id="Group_35" data-name="Group 35">
        <g id="Driver_icon-2" data-name="Driver icon">
          <path id="Path_30" data-name="Path 30" d="M1162.109,571.347h34.074a4.916,4.916,0,0,0,4.916-4.916h0v-7.909a13.884,13.884,0,0,0-13.883-13.885h-2.792" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <path id="Path_30-2" data-name="Path 30-2" d="M1173.867,544.638h-2.791a14.116,14.116,0,0,0-2.35.2" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <line id="Line_21" data-name="Line 21" y2="26.031" transform="translate(1191.507 545.317)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <path id="Path_31" data-name="Path 31" d="M1173.869,542.045v7.84a3.837,3.837,0,0,0,3.837,3.837h2.881a3.837,3.837,0,0,0,3.838-3.836h0v-7.84" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_32" data-name="Path 32" d="M1162.766,527.243a16.4,16.4,0,0,0,32.774-.706V515.211a13.429,13.429,0,0,0-13.429-13.429h-5.932a13.394,13.394,0,0,0-9.827,4.277" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_33" data-name="Path 33" d="M1170.877,516.255a43.748,43.748,0,0,1,8.269-.707c12.954,0,16.394,4.234,16.394,4.234" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_34" data-name="Path 34" d="M1195.618,519.781l-3.222,3a5.322,5.322,0,0,1-5.94.9h0a16.825,16.825,0,0,0-14.62,0h0a5.324,5.324,0,0,1-5.941-.9l-.875-1.41" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_35" data-name="Path 35" d="M1195.54,528.549a5.48,5.48,0,0,0,0-10.961" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_36" data-name="Path 36" d="M1174.526,534.225a5.484,5.484,0,0,0,9.238,0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
        </g>
        <g id="Driver_icon-2-2" data-name="Driver icon-2">
          <path id="Path_30-3" data-name="Path 30-3" d="M1107.1,544.638h-2.792a13.884,13.884,0,0,0-13.883,13.885h0v7.909a4.916,4.916,0,0,0,4.916,4.916h34.073" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <path id="Path_30-4" data-name="Path 30-4" d="M1122.8,544.836a14.143,14.143,0,0,0-2.351-.2h-2.788" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <line id="Line_21-2" data-name="Line 21-2" y1="26.031" transform="translate(1100.02 545.317)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <path id="Path_31-2" data-name="Path 31-2" d="M1107.1,542.045v7.84a3.837,3.837,0,0,0,3.837,3.837h2.882a3.838,3.838,0,0,0,3.838-3.837v-7.84" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_32-2" data-name="Path 32-2" d="M1125.175,506.059a13.4,13.4,0,0,0-9.827-4.277h-5.932a13.429,13.429,0,0,0-13.431,13.427v11.329a16.4,16.4,0,0,0,32.774.706" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_33-2" data-name="Path 33-2" d="M1095.985,519.78s3.44-4.234,16.394-4.234a43.737,43.737,0,0,1,8.268.707" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_34-2" data-name="Path 34-2" d="M1126.509,521.371l-.875,1.41a5.324,5.324,0,0,1-5.941.9h0a16.825,16.825,0,0,0-14.62,0h0a5.323,5.323,0,0,1-5.941-.9l-3.222-3" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_35-2" data-name="Path 35-2" d="M1095.987,517.588a5.48,5.48,0,1,0,0,10.961" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_36-2" data-name="Path 36-2" d="M1107.763,534.225a5.484,5.484,0,0,0,9.238,0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
        </g>
        <g id="Driver_icon-3" data-name="Driver icon-3">
          <path id="Path_30-5" data-name="Path 30-5" d="M1139.03,538.495H1135.6a17.077,17.077,0,0,0-17.077,17.077h0V565.3a6.047,6.047,0,0,0,6.047,6.047h41.909a6.046,6.046,0,0,0,6.047-6.047v-9.727a17.077,17.077,0,0,0-17.076-17.078h-3.434" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <line id="Line_21-3" data-name="Line 21-3" y2="32.017" transform="translate(1160.726 539.33)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <line id="Line_22" data-name="Line 22" y2="32.017" transform="translate(1130.584 539.33)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="4"/>
          <path id="Path_31-3" data-name="Path 31-3" d="M1139.03,535.307v9.643a4.72,4.72,0,0,0,4.72,4.72h3.543a4.721,4.721,0,0,0,4.72-4.72v-9.643" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_32-3" data-name="Path 32-3" d="M1149.169,485.784h-7.3a16.517,16.517,0,0,0-16.517,16.517v13.932a20.165,20.165,0,1,0,40.331,0V502.3A16.518,16.518,0,0,0,1149.169,485.784Z" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_33-3" data-name="Path 33-3" d="M1125.356,507.918s4.232-5.209,20.165-5.209,20.165,5.209,20.165,5.209" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_34-3" data-name="Path 34-3" d="M1165.782,507.922l-3.963,3.69a6.547,6.547,0,0,1-7.307,1.106h0a20.7,20.7,0,0,0-17.982,0h0a6.547,6.547,0,0,1-7.307-1.106l-3.964-6.391a6.741,6.741,0,0,0,0,13.482" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_35-3" data-name="Path 35-3" d="M1165.687,518.707a6.741,6.741,0,0,0,0-13.482" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="4"/>
          <path id="Path_36-3" data-name="Path 36-3" d="M1139.841,525.687a6.743,6.743,0,0,0,11.362,0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
        </g>
      </g>
    </g>
  </svg>
);

export default DriverHireIcon;
