import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useLocalized from 'CustomHooks/useLocalized';

const NavigationLink = ({
  id,
  label,
  href,
  inverted,
}) => {
  return (
    <Link id={id} to={`/#${href}`} className={`navigation-link ${inverted ? "inverted" : ""}`}>
      {useLocalized(label)}
    </Link>
  );
}

NavigationLink.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  href: PropTypes.string,
  inverted: PropTypes.bool,
}

export default NavigationLink;
