import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useLocalized from 'CustomHooks/useLocalized';

const MobileNavigationLink = ({
  id,
  label,
  href,
  onClick,
}) => {
  return (
    <Link id={id} to={`/#${href}`} onClick={onClick} className="mobile-navigation-link">
      {useLocalized(label)}
    </Link>
  );
}

MobileNavigationLink.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
}

export default MobileNavigationLink;

