class ValidationService {
  static isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  static hasErrors(errors) {
    let hasError = false;
    const errorEntries = Object.entries(errors);
    let i = 0;
    while (hasError === false && i < errorEntries.length) {
      const [, errorValue] = errorEntries[i];
      if (errorValue) {
        hasError = errorValue;
      }
      i += 1;
    }
    return hasError;
  }
}

export default ValidationService;
