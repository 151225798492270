import LocalizationService from 'Services/LocalizationService';
import { SUPPORTED_LOCALES } from 'Constants/Options';

class ErrorMessageService {
  static getErrorMessage(code) {

    // If needed locale can be received from a context right now we are supporting only one language so it is hardcoded
    const locale = SUPPORTED_LOCALES.en_GB;
    const translatedErrorMessage = LocalizationService.shared().getLocalizedText(locale, `error_message_${code}`);
    if (!translatedErrorMessage) {
      return LocalizationService.shared().getLocalizedText(locale, 'something_happened')
    }
    return translatedErrorMessage;
  }
}

export default ErrorMessageService;
