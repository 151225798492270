import { Alert } from '@material-ui/lab';

const Notifications = ({
  notifications,
}) => {
  return (
    <div className="notifications-container">
      {notifications && notifications.map(({
        text,
        severity,
      }, index) => (
        <div key={index} className="notifications-container__item">
          <Alert severity={severity}>
            {text}
          </Alert>
        </div>
      ))}
    </div>
  );
}

export default Notifications;
