const VehicleHireIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="136.481" height="72.271" viewBox="0 0 136.481 72.271" {...props}>
    <g id="Truck_icon" data-name="Truck icon" transform="translate(-891.987 -503.616)">
      <path id="Path_101" data-name="Path 101" d="M1026.857,543.856l-2.346-2.345-4.724-14.175a1.747,1.747,0,0,0-.524-3.407h-16.9v-7.576a3.613,3.613,0,0,0-3.609-3.608H904.239a3.611,3.611,0,0,0-3.608,3.608v41.014a3.612,3.612,0,0,0,3.608,3.609h1.984v3.844a3.611,3.611,0,0,0,3.608,3.608h4.019a9.195,9.195,0,0,0,16.49,3.637,9.2,9.2,0,0,0,16.491-3.637h52.214a9.2,9.2,0,0,0,18.234-1.747v-.119h7.581a3.613,3.613,0,0,0,3.608-3.608V547.725A5.433,5.433,0,0,0,1026.857,543.856Z" fill="rgba(2,158,255,0.5)"/>
      <path id="Path_25" data-name="Path 25" d="M917.471,563.363h-.021a2.008,2.008,0,1,0,.021,0Z"/>
      <path id="Path_26" data-name="Path 26" d="M934.542,563.363h-.021a2.008,2.008,0,1,0,.021,0Z"/>
      <path id="Path_27" data-name="Path 27" d="M1004.959,563.363h-.022a2.007,2.007,0,1,0,.022,0Z"/>
      <path id="Path_28" data-name="Path 28" d="M1026.438,539.225l-2.685-2.684-5.407-16.224a2,2,0,0,0-.6-3.9H998.4v-8.671a4.135,4.135,0,0,0-4.13-4.13H896.117a4.135,4.135,0,0,0-4.13,4.13V554.69a4.134,4.134,0,0,0,4.13,4.13h2.271v4.4a4.134,4.134,0,0,0,4.13,4.13h4.6a10.524,10.524,0,0,0,18.874,4.162,10.525,10.525,0,0,0,18.875-4.162H994.6a10.531,10.531,0,0,0,20.871-2v-.136h8.676a4.135,4.135,0,0,0,4.13-4.13v-17.43A6.222,6.222,0,0,0,1026.438,539.225ZM907.111,563.363h-4.6a.138.138,0,0,1-.137-.138v-4.4H909.2a10.5,10.5,0,0,0-2.091,4.538Zm10.34,8.535a6.539,6.539,0,0,1-.041-13.077h.082a6.539,6.539,0,0,1-.041,13.077Zm17.071,0a6.539,6.539,0,0,1-.041-13.077h.082a6.539,6.539,0,0,1-.041,13.077Zm60.077-8.535H944.862a10.48,10.48,0,0,0-2.093-4.542h38.7a2,2,0,0,0,0-3.993H896.115a.137.137,0,0,1-.137-.137V507.746a.137.137,0,0,1,.137-.137h98.157a.138.138,0,0,1,.137.137v47.082h-4.4a2,2,0,0,0,0,3.993H996.7a10.5,10.5,0,0,0-2.1,4.542Zm25.35-25.607h-12.878a.137.137,0,0,1-.138-.136v-8.674h10.079ZM1004.937,571.9a6.539,6.539,0,0,1-.04-13.077h.052a6.539,6.539,0,1,1-.014,13.078Zm19.342-23.471h-2.271a2,2,0,0,0-.237,3.993,1.872,1.872,0,0,0,.237,0h2.271v8.672a.137.137,0,0,1-.136.138h-9.518a10.547,10.547,0,0,0-9.688-6.4H998.4V520.549h0a.138.138,0,0,1,.136-.138h15.633l1.514,4.542h-10.744a2,2,0,0,0-2,2v10.669a4.134,4.134,0,0,0,4.13,4.13h16.243l.3.3a2.257,2.257,0,0,1,.666,1.606v4.772Z"/>
      <path id="Path_29" data-name="Path 29" d="M902.512,550.286h85.354a2,2,0,0,0,0-3.993H902.512a2,2,0,0,0,0,3.993Z"/>
    </g>
  </svg>
);

export default VehicleHireIcon;
