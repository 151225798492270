const Map = () => {
  return (
      <iframe
          width="100%"
          height="100%"
          title="google-maps"
          src="https://maps.google.com/maps?q=98%20Bushy%20Hill%20Drive%20Merrow%20Guildford%20GU1%202UH&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
      />
  );
}

export default Map;
