import PropTypes from 'prop-types';
import { Container, Hidden } from '@material-ui/core';

import useLocalized from 'CustomHooks/useLocalized';
import DriverHireIcon from 'Screens/Main/Sections/Services/Assets/DriverHireIcon';
import BookDriverForm from 'Screens/Main/Sections/Header/Blocks/BookDriverForm/BookDriverForm';
import ImagePresenter from 'Components/ImagePresenter/ImagePresenter';
import { DriverHirebackground } from 'Screens/Services/Assets';

const DRIVER_TYPES = [
  'Class 1 Lorry Drivers with Crane Licence',
  'Class 2 Lorry Drivers with Crane Licence',
  'Van Drivers',
  'Folk Lift Drivers',
  'Pickers',
];

const DRIVER_HIRE_RATES = [``, ``];

const DriverHireScreen = ({
  branchLocationOptions,
}) => {
  return (
    <>
      <div className="service-detail-screen">
        <Container className="service-detail-screen__container">
          <div className="service-detail-screen__left">
            <div className="service-detail-screen__left--title">
              {useLocalized('services_driver_hire')}
              <DriverHireIcon className="service-detail-screen__left--title--icon" />
            </div>
            <div className="service-detail-screen__left--description">
              {useLocalized('services_driver_hire_content_description')}
            </div>

            <div className="service-detail-screen__left--content">
              <div className="service-detail-screen__left--content--title">
                {useLocalized('driver_types')}
              </div>
              <ul className="service-detail-screen__left--content--list">
                {DRIVER_TYPES.map(driver => <li key={driver}>{driver}</li>)}
              </ul>
            </div>
            {DRIVER_HIRE_RATES.map((rateText, index) => (
              <div key={index} className="service-detail-screen__left--content--description" dangerouslySetInnerHTML={{ __html: rateText }} />
            ))}
          </div>
          <div className="service-detail-screen__right">
            <BookDriverForm branchLocationOptions={branchLocationOptions} className="service-detail-screen__right--form" />
          </div>
        </Container>
        <div className="service-detail-screen-background-layer">
          <div className="service-detail-screen-background-layer__left"></div>
          <div className="service-detail-screen-background-layer__right" style={{ backgroundImage: `url(${DriverHirebackground})` }}/>
        </div>
        <Hidden mdUp>
          <ImagePresenter
            containerClass="service-detail-screen__mobile-image-container"
            imageClass="service-detail-screen__mobile-image-container--image"
            src={DriverHirebackground}
          />
        </Hidden>
      </div>
    </>
  );
}

DriverHireScreen.propTypes = {
  branchLocationOptions: PropTypes.object,
};

export default DriverHireScreen;
