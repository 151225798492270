import { BASE_URL } from 'Constants/Options';

class WebService {
  static sharedInstance;
  static getSharedInstance = () => {
    if (WebService.sharedInstance) {
      return WebService.sharedInstance;
    }
    WebService.sharedInstance = new WebService();
    return WebService.sharedInstance;
  };

  callWebservice = ({ method, path, data }) => new Promise((resolve, reject) => {
    const url = `${BASE_URL}/api/${path}`;
    const headers = { 'Content-Type': 'application/json' };
    fetch(url, {
      method,
      headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        switch (response.status) {
          case 400:
          case 401:
          case 404:
          case 409:
            response.json().then((message) => reject(message.custom_code));
            return;
          case 200:
            resolve(response.json());
            return;
          case 204:
            resolve();
            return;
          default:
            response.json().then((message) => reject(message.custom_code));
            return;
        }
      }).catch((error) => {
        reject(error);
      });
  });
}

export default WebService;
