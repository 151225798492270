import PropTypes from 'prop-types';

import ImagePresenter from 'Components/ImagePresenter/ImagePresenter'

const VehicleElement = ({
  vehicle,
}) => {
  const {
    image,
    title,
    properties,
    subDescription,
  } = vehicle;
  return (
    <div className="vehicle-element">
      <div className="vehicle-element__title">
        {title}
      </div>
      <div className="vehicle-element__content">
        <ImagePresenter
          containerClass="vehicle-element__content--image-container"
          src={image}
        />
        <ul className="vehicle-element__content--list">
          {properties.map(property => <li key={property} className="vehicle-element__content--list--element">{property}</li>)}
          <div className="vehicle-element__content--list--end-element" dangerouslySetInnerHTML={{ __html: subDescription }} />
        </ul>
      </div>
    </div>
  )
};

VehicleElement.propTypes = {
  vehicle: PropTypes.object,
}

export default VehicleElement;
