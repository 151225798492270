import { Container } from '@material-ui/core';

import { NAVIGATION_SECTION_IDS } from 'Constants/NavigationConstants';
import useLocalized from 'CustomHooks/useLocalized';
import ImagePresenter from 'Components/ImagePresenter/ImagePresenter';
import AboutUsImage from 'Screens/Main/Sections/AboutUs/Assets/AboutUsImage.png';

const AboutUs = () => {
  return (
    <div id={NAVIGATION_SECTION_IDS.aboutUs} className="about-us-section">
      <Container className="about-us-section-top-layer">
        <div className="about-us-section-top-layer__left">
          <div className="title">
            {useLocalized('about_us_title')}
          </div>
          <div className="line" />
          <div className="description">
            {useLocalized('about_us_description1')}
          </div>
          <div className="description">
            {useLocalized('about_us_description2')}
          </div>
          <div className="description">
            {useLocalized('about_us_description3')}
          </div>
        </div>
        <div className="about-us-section-top-layer__right" />
      </Container>
      <div className="about-us-section-background-layer">
        <div className="about-us-section-background-layer__left" />
        <ImagePresenter
          containerClass="about-us-section-background-layer__right"
          imageClass="about-us-section-background-layer__right--image"
          src={AboutUsImage}
        />
      </div>
    </div>
  )
}

export default AboutUs;
